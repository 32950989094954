// Not great solution, if possible find better, best I can do with the current time

#react-select-1-listbox>div,
#react-select-2-listbox>div,
#react-select-3-listbox>div,
#react-select-4-listbox>div,
#react-select-5-listbox>div,
#react-select-6-listbox>div,
#react-select-7-listbox>div,
#react-select-8-listbox>div,
#react-select-9-listbox>div,
#react-select-10-listbox>div,
#react-select-11-listbox>div,
#react-select-12-listbox>div,
#react-select-13-listbox>div,
#react-select-14-listbox>div,
#react-select-15-listbox>div,
#react-select-16-listbox>div,
#react-select-17-listbox>div,
#react-select-18-listbox>div,
#react-select-19-listbox>div,
#react-select-20-listbox>div {
  background-color: $background;
  padding: 0px;

  &>div {

    &:hover {
      background-color: $primary;
      color: #000;
    }

    &:active {
      background-color: $light;
      color: #000;
    }
  }
}

div[class*="-Input"] {
  color: rgb(255, 255, 255);
}

#site-dropdown>div,
#split-by-dropdown>div {
  box-shadow: none;

  &:focus,
  &:active {
    border-color: $primary;
  }

  &:hover {
    border-color: rgb(179, 179, 179);
  }
}